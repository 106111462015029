import { createSlice,createAsyncThunk,createAction} from "@reduxjs/toolkit";
import {collectionService} from './collectionService'
import { toast } from "react-toastify";


export const getAllCollection=createAsyncThunk("collection/get",async(thunkAPI)=>{
    try{
        return await collectionService.getCollection()
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const createSingleCollection=createAsyncThunk("collection/post-single",async(data,thunkAPI)=>{
    try{
        return await collectionService.createSingleCollection(data)
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const deleteSingleCollection=createAsyncThunk("collection/delete-single",async(data,thunkAPI)=>{
    try{
        return await collectionService.deleteSingleCollection(data)
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const getTotalCollection=createAsyncThunk("collection/get-total",async(thunkAPI)=>{
    try{
        return await collectionService.getTotalCollection()
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const getsingleCollection=createAsyncThunk("collection/get-single",async(data,thunkAPI)=>{
    try{
        return await collectionService.getSingleCollection(data)
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})

export const updatesingleCollection=createAsyncThunk("collection/update",async(data,thunkAPI)=>{
    try{
        return await collectionService.updateSingleCollection(data)
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const getinnerCollection=createAsyncThunk("collection/get-inner",async(data,thunkAPI)=>{
    try{
        return await collectionService.getInnerCollection(data)
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const updateinnerCollection=createAsyncThunk("collection/put-inner",async(data,thunkAPI)=>{
    try{
        return await collectionService.updateInnerCollection(data)
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const deleteinnerCollection=createAsyncThunk("collection/delete-inner",async(data,thunkAPI)=>{
    try{
        return await collectionService.deleteInnerCollection(data)
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const createinnerCollection=createAsyncThunk("collection/post-inner",async(data,thunkAPI)=>{
    try{
        return await collectionService.createInnerCollection(data)
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
})
export const resetState = createAction("Reset_all");


const collectionState={
    collection:"",
    isError:false,
    isLoading:false,
    isSuccess:false,
    message:""
}


export const collectionSlice=createSlice({
    name:"collection",
    initialState:collectionState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(getAllCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.collection=action.payload;
        }).addCase(getAllCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(getTotalCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(getTotalCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.total=action.payload;
        }).addCase(getTotalCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(createSingleCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(createSingleCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.createSingle=action.payload;
        }).addCase(createSingleCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(deleteSingleCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(deleteSingleCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.deleteSingle=action.payload;
        }).addCase(deleteSingleCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(getinnerCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(getinnerCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.innercollection=action.payload;
        }).addCase(getinnerCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(updateinnerCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(updateinnerCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.updateinnercollection=action.payload;
            if(state.isSuccess===true){
                toast.success("Collection Updated")
            }
        }).addCase(updateinnerCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(createinnerCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(createinnerCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.createinnercollection=action.payload;
            if(state.isSuccess===true){
                toast.success("Collection Updated")
            }
        }).addCase(createinnerCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(deleteinnerCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(deleteinnerCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.deleteinnercollection=action.payload;
            if(state.isSuccess===true){
                toast.success("Collection Updated")
            }
        }).addCase(deleteinnerCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(updatesingleCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(updatesingleCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.updateCollection=action.payload;
            if(state.isSuccess===true){
                toast.success("Collection Updated")
            }
        }).addCase(updatesingleCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
        .addCase(getsingleCollection.pending,(state)=>{
            state.isLoading=true;
        }).addCase(getsingleCollection.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.singlecollection=action.payload;
        }).addCase(getsingleCollection.rejected,(state,action)=>{
            state.isLoading=false;
            state.isError=true;
            state.isSuccess=false;
            state.message=action.error;
        })
      .addCase(resetState, () => collectionState);

    }
})
export default collectionSlice.reducer