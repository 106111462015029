import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const getCollection=async()=>{
    
    const response =await axios.get(`${base_url}collection/`)


        if (response.data) {
            return response.data;
        }
}
const getSingleCollection=async(data)=>{
    
    const response =await axios.get(`${base_url}collection/${data.getCollectionId}`)


        if (response.data) {
            return response.data;
        }
}
const createSingleCollection=async(data)=>{
    
    const response =await axios.post(`${base_url}collection/`,data)


        if (response.data) {
            return response.data;
        }
}
const deleteSingleCollection=async(data)=>{
    
    const response =await axios.delete(`${base_url}collection/${data.id}`)


        if (response.data) {
            return response.data;
        }
}

const updateSingleCollection=async(data)=>{
    
    const response =await axios.put(`${base_url}collection/${data.id}`,data.data)


        if (response.data) {
            return response.data;
        }
}

const getInnerCollection=async(data)=>{
    
    const response =await axios.get(`${base_url}collection/${data.getCollectionId}/inner/${data.getInnerCollectionId}`)


        if (response.data) {
            return response.data;
        }
}
const getTotalCollection=async()=>{
    
    const response =await axios.get(`${base_url}collection/totalcollections`)


        if (response.data) {
            return response.data;
        }
}

const updateInnerCollection=async(data)=>{
    
    const response =await axios.put(`${base_url}collection/${data.id}/inner/${data.id2}`,data.data)


        if (response.data) {
            return response.data;
        }
}

const deleteInnerCollection=async(data)=>{
    
    const response =await axios.delete(`${base_url}collection/${data.id}/inner/${data.id1}`)


        if (response.data) {
            return response.data;
        }
}
const createInnerCollection=async(data)=>{
    
    const response =await axios.post(`${base_url}collection/${data.id}/inner`,data.main)


        if (response.data) {
            return response.data;
        }
}


export const collectionService={
    getCollection,
    getInnerCollection,
    updateInnerCollection,
    updateSingleCollection,
    getSingleCollection,
    getTotalCollection,
    createSingleCollection,
    deleteSingleCollection,
    createInnerCollection,
    deleteInnerCollection
}