import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import pageService from "./pageService";
import {toast} from 'react-hot-toast'
let loadingToast = null; // Reference to the loading toast
export const getPages = createAsyncThunk(
  "page/get-pages",
  async (thunkAPI) => {
    try {
      return await pageService.getPages();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createPage = createAsyncThunk(
  "page/create-page",
  async (pageData, thunkAPI) => {
    try {
      return await pageService.createPage(pageData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAPage = createAsyncThunk(
  "page/get-page",
  async (id, thunkAPI) => {
    try {
      return await pageService.getPage(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAPage = createAsyncThunk(
  "page/update-page",
  async (page, thunkAPI) => {
    try {
      return await pageService.updatePage(page);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAPage = createAsyncThunk(
  "page/delete-page",
  async (id, thunkAPI) => {
    try {
      return await pageService.deletePage(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  pages: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const pageSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.pages = action.payload;
      })
      .addCase(getPages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdPage = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Page Created")
        }
      })
      .addCase(createPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(updateAPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedPage = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Page Updated")
        }
      })
      .addCase(updateAPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(getAPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singlePage = action.payload;
      })
      .addCase(getAPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedPage = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Page Deleted")
        }
        
      })
      .addCase(deleteAPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(resetState, () => initialState);
  },
});
export default pageSlice.reducer;
