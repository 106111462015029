import React, { useState } from 'react'
import './forgot.css'
import {adminCreate} from '../../features/auth/authSlice'
import {useDispatch} from 'react-redux'
import toast from 'react-hot-toast'
const NewAdmin = () => {
const dispatch=useDispatch()
const [firstname,setFirstname]=useState("")
const [lastname,setLastname]=useState("")
const [email,setEmail]=useState("")
const [mobile,setMobile]=useState(null)
const [password,setPassword]=useState("")
const [secretWord,setSecretWord]=useState("")


const createAdmin=()=>{
  if(firstname==="" || lastname==="" || email==="" || mobile===null || password==="" || secretWord===""){
    toast.error("Please Fill all the fields")
  }
  else{
    dispatch(adminCreate({firstname,lastname,email,mobile,password,secretWord}))
  }
}


  return (
    <div className='forgot'>
      <div className='form'>
      <div className="email">
            <input type="text" placeholder='Firstname' value={firstname} onChange={(e)=>setFirstname(e.target.value)}/>
        </div>
        <div className="email">
            <input type="text" placeholder='Lastname' value={lastname} onChange={(e)=>setLastname(e.target.value)}/>
        </div>
        <div className="email">
            <input type="email" placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </div>
        <div className="email">
            <input type="number" placeholder='Mobile' value={mobile} onChange={(e)=>setMobile(e.target.value)}/>
        </div>
        <div className="email">
            <input type="text" placeholder='Password' value={password} onChange={(e)=>setPassword(e.target.value)}/>
        </div>
        <div className="email">
            <input type="text" placeholder='Secret Code' value={secretWord} onChange={(e)=>setSecretWord(e.target.value)}/>
        </div>
        <button onClick={createAdmin}>Create Admin</button>
      </div>
    </div>
  )
}

export default NewAdmin
