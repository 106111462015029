import React,{useEffect} from 'react'
import './review.css'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from "react-redux";
import { getallratings } from '../../features/product/productSlice';
const ReviewList = () => {
    const dispatch=useDispatch()
    const getRatings=useSelector((state)=>state?.product?.getratings)
    console.log(getRatings)
    useEffect(()=>{
      dispatch(getallratings())
    },[])
    return (
    <div className='reviews'>
      <div className="review-head">
        <p>All Reviews</p>
        <button>Add Review</button>
      </div>
      <div className="ratings">
      {
        getRatings?.map((item,index)=>{
          return <div className="rating" key={index}>
            <img src={item?.productImages[0]?.url} alt="" />
            <div className="detail">
            <p className="name">{item?.name}</p>
            <Stack spacing={1} className="stars">
          <Rating name="size-small" value={item?.star} size="small" />
    
        </Stack>            <p className="msg">{item?.comment}</p>
            </div>
          </div>
        })
      }
      </div>
      
    </div>
  )
}

export default ReviewList
