import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import productService from "./productService";
import {toast} from 'react-hot-toast'
let loadingToast = null; // Reference to the loading toast

export const getsearchProducts=createAsyncThunk(
  'product/get-search',
  async(data,thunkAPI)=>{
    try{
      return await productService.getSearchProducts(data);
    }catch(error){
      return thunkAPI.rejectWithValue(error);

    }
  }
)

export const getProducts = createAsyncThunk(
  "product/get-products",
  async (data,thunkAPI) => {
    try {
      return await productService.getProducts(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getallProducts=createAsyncThunk("product/get",async(data,thunkAPI)=>{
  try{
      return await productService.getallProducts(data)
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})

export const getAllProducts = createAsyncThunk(
  "product/get-all-products",
  async (data,thunkAPI) => {
    try {
      return await productService.getAllProducts(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createProducts = createAsyncThunk(
  "product/create-products",
  async (productData, thunkAPI) => {
    try {
      return await productService.createProduct(productData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAProduct = createAsyncThunk(
  "product/get-product",
  async (id, thunkAPI) => {
    try {
      return await productService.getProduct(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAProduct = createAsyncThunk(
  "product/update-product",
  async (product, thunkAPI) => {
    try {
      return await productService.updateProduct(product);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAProduct = createAsyncThunk(
  "product/delete-product",
  async (id, thunkAPI) => {
    try {
      return await productService.deleteProduct(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const rating = createAsyncThunk(
  "product/rateProduct",
  async ({ prodId, star, comment, name, email }, thunkAPI) => {
      try {
          return await productService.rateProduct(prodId, star, comment, name, email);
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);
export const getallratings=createAsyncThunk("product/getratings",async(thunkAPI)=>{
  try{
      return await productService.getAllRatings()
  }catch(error){
      return thunkAPI.rejectWithValue(error)
  }
})


export const resetState = createAction("Reset_all");

const initialState = {
  products: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getallProducts.fulfilled,(state,action)=>{
      state.isLoading=false;
      state.isError=false;
      state.isSuccess=true;
      state.product=action.payload;
  })
  .addCase(getallProducts.pending,(state)=>{
      state.isLoading=true;
  }).addCase(getallProducts.rejected,(state,action)=>{
      state.isLoading=false;
      state.isError=true;
      state.isSuccess=false;
      state.message=action.error;
  })
  .addCase(getsearchProducts.fulfilled,(state,action)=>{
    state.isLoading=false;
    state.isError=false;
    state.isSuccess=true;
    state.searchProduct=action.payload;
})
.addCase(getsearchProducts.pending,(state)=>{
    state.isLoading=true;
}).addCase(getsearchProducts.rejected,(state,action)=>{
    state.isLoading=false;
    state.isError=true;
    state.isSuccess=false;
    state.message=action.error;
})
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAllProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.prdt = action.payload;
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createProducts.pending, (state) => {
        state.isLoading = true;
        loadingToast = toast.loading('Creating Product...', { autoClose: false });

      })
      .addCase(createProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdProduct = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Product Created")
        }
      })
      .addCase(createProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(deleteAProduct.pending, (state) => {
        state.isLoading = true;
        loadingToast = toast.loading('Deleting Product...', { autoClose: false });

      })
      .addCase(deleteAProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedProduct = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Product Deleted")
        }
      })
      .addCase(deleteAProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(getAProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.productTitle= action.payload.title;
        state.productAlt=action.payload.alt;
      state.productDescription= action.payload.description;
      state.productCategory= action.payload.category;
      state.productImages= action.payload.images;
      state.productPrice=action.payload.price;
      state.productContent=action.payload.content;
      state.productDashPrice=action.payload.dashPrice;
      state.productHandle=action.payload.handle;
      state.productSku=action.payload.sku;
      state.productState=action.payload.state;
      state.productCollectionName=action.payload.collectionName;
      state.productVariants=action.payload.variants;
      state.productTags=action.payload.tags;
      state.metaTitle=action.payload.metaTitle;
      state.metaDesc=action.payload.metaDesc;
      state.isFeatured=action.payload.isFeatured;
      state.sold=action.payload.sold
      })
      .addCase(getAProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAProduct.pending, (state) => {
        state.isLoading = true;
        loadingToast = toast.loading('Updating Product...', { autoClose: false });

      })
      .addCase(updateAProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedProduct = action.payload;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isSuccess===true){
          toast.success("Product Updated")
        }
      })
      .addCase(updateAProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        if (loadingToast) {
          toast.dismiss(loadingToast);
      }
        if(state.isError===true){
          toast.error("Something Went Wrong")
        }
      })
      .addCase(getallratings.pending,(state)=>{
        state.isLoading=true;

    }).addCase(getallratings.fulfilled,(state,action)=>{
        state.isLoading=false;
        state.isError=false;
        state.isSuccess=true;
        state.getratings=action.payload;

    }).addCase(getallratings.rejected,(state,action)=>{
        state.isLoading=false;
        state.isError=true;
        state.isSuccess=false;
        state.message=action.error;
    })
    .addCase(rating.pending,(state)=>{
        state.isLoading=true;
    }).addCase(rating.fulfilled,(state,action)=>{
        state.isLoading=false;
        state.isError=false;
        state.isSuccess=true;
        state.postRating=action.payload;
        if(state.isSuccess===true){
            toast.success("Rating Posted")
        }
    }).addCase(rating.rejected,(state,action)=>{
        state.isLoading=false;
        state.isError=true;
        state.isSuccess=false;
        state.message=action.error;
    })
      .addCase(resetState, () => initialState);
  },
});
export default productSlice.reducer;
