import React, { useState, useEffect } from 'react';
import { createhistory } from '../../features/auth/authSlice';
import './page.css'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { getAPage, resetState, updateAPage, deleteAPage,createPage } from '../../features/page/pageSlice';
import { toast } from "react-hot-toast";
import ReactQuill from "react-quill";
const Page = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [handle, setHandle] = useState("");
  const location = useLocation();
  const getPageId = location.pathname.split("/")[2];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageState = useSelector((state) => state?.page?.singlePage);
  useEffect(() => {
    setTitle(pageState?.title);
    setDesc(pageState?.desc);
    setMetaTitle(pageState?.metaTitle);
    setMetaDesc(pageState?.metaDesc);
    setHandle(pageState?.handle);
  }, [pageState]);

  useEffect(() => {
    if (getPageId !== undefined) {
      dispatch(getAPage(getPageId));
    } else {
      dispatch(resetState());
    }
  }, [dispatch, getPageId]);

  const createPages = () => {
    if (title === "" || handle === "" || desc === "") {
      toast.info("Please Fill All The Required Fields");
    } else {
      if (getPageId !== undefined) {
        dispatch(updateAPage({
          id: getPageId, data: {
            title: title,
            desc: desc,
            handle: handle,
            metaTitle: metaTitle,
            metaDesc: metaDesc,
            createdAt: pageState?.createdAt,
          }
        }));
        dispatch(createhistory({ name: user?.firstname, title: title, sku: desc, productchange: `Updated a Page`, time: new Date() }));
      } else {
        dispatch(createPage({
          title: title,
          desc: desc,
          handle: handle,
          metaTitle: metaTitle,
          metaDesc: metaDesc,
        }));
        dispatch(createhistory({ name: user?.firstname, title: title, sku: desc, productchange: `Created a Page`, time: new Date() }));
      }
    }
  };

  const deletePage = () => {
    dispatch(deleteAPage(getPageId));
    dispatch(createhistory({ name: user?.firstname, title: title, sku: desc, productchange: `Deleted a Page`, time: new Date() }));
    setTimeout(() => {
      navigate("/page");
    }, 1000);
  };



  return (
    <div className='collection spage'>
      <div className="collection-head">
        <p>Create Page</p>
        <div className="right">
          <button onClick={deletePage}>Delete</button>
          <button onClick={createPages}>Save</button>
        </div>
      </div>
      <div className="collection-page">
        <div>
          <div className="category">
            <p>Title</p>
            <input type="text" placeholder='Enter Name' onChange={(e) => setTitle(e.target.value)} value={title} />
          </div>
          <div className="category">
            <p>Description</p>
            <ReactQuill
                  className='desc'
                  theme="snow"
                  name="desc"
                  modules={modules}
                  formats={formats}
                  onChange={setDesc}
                  value={desc}
                />
          </div>
          <div className="handle">
            <p>Handle</p>
            <input type="text" placeholder='Enter Url' onChange={(e) => setHandle(e.target.value)} value={handle} />
          </div>
          <div className="metaTitle">
            <p>Meta Title</p>
            <input type="text" placeholder='Enter Meta Title' onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} />
          </div>
          <div className="metaDesc">
            <p>Meta Description</p>
            <textarea placeholder='Enter Meta Description' onChange={(e) => setMetaDesc(e.target.value)} value={metaDesc}></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
