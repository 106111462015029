import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getPages = async () => {
  const response = await axios.get(`${base_url}page/`);

  return response.data;
};
const createPage = async (page) => {
  const response = await axios.post(`${base_url}page/`, page, config);

  return response.data;
};

const updatePage = async (page) => {
  const response = await axios.put(
    `${base_url}page/${page.id}`,
    page.data ,
    config
  );

  return response.data;
};
const getPage = async (id) => {
  const response = await axios.get(`${base_url}page/${id}`, config);

  return response.data;
};

const deletePage = async (id) => {
  const response = await axios.delete(`${base_url}page/${id}`, config);

  return response.data;
};
const pageService = {
  getPages,
  createPage,
  updatePage,
  getPage,
  deletePage,
};

export default pageService;
