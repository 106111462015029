import React, { useEffect } from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import { getPages } from '../../features/page/pageSlice'
import './page.css'
const PageList = () => {
    const dispatch=useDispatch()
    const navigate=useNavigate()

    
    const pageState = useSelector((state) => state?.page?.pages);
    useEffect(()=>{
        dispatch(getPages())
    },[dispatch])

  return (
    <div className='collection all-page'>
      <div className="collection-head">
        <p>Pages</p>
        <button onClick={()=>navigate("/pages")}>Add Page</button>
      </div>
      <div className="collection-list">
        {
            pageState?.map((item,index)=>{
                return(
                    <Link to={`/page/${item?._id}`}>
                        <div className="collection-card">
                            <div className="right">
                            <p className='name'>{item?.title}</p>
                            {/* <p>{item?.products?.length}</p> */}
                            <p className='status' style={{position:'relative',background:'transparent'}}>{item?.handle}</p>
                            </div>
                        </div>
                    </Link>
                )
            })
        }
      </div>
    </div>
  )
}

export default PageList
