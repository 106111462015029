import React from 'react'

const SingleCustomer = () => {
  return (
    <div className='customer'>
      hello from customer
    </div>
  )
}

export default SingleCustomer
