import React, { useEffect, useState } from 'react'
import './customer.css'
import {useDispatch,useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import * as XLSX from 'xlsx';
import { getUsers,getUsers1 } from '../../features/cutomers/customerSlice'
const CustomerList = () => {
    const [page,setPage]=useState(1)
    const [numOrders, setNumOrders] = useState('');

    const dispatch=useDispatch()
    const navigate=useNavigate()
    const nextPage=()=>{
        setPage(page+1)
      }
      const prevPage=()=>{
        setPage(page-1)
      }

    
    const customerState = useSelector((state) => state?.customer?.customers);
    const customerState1 = useSelector((state) => state?.customer?.users);
    useEffect(()=>{
      dispatch(getUsers1())
  },[dispatch])
    useEffect(()=>{
        dispatch(getUsers({page}))
    },[dispatch,page])

    const exportToExcel = () => {
      const ordersToExport = customerState1?.user?.slice(-numOrders); // Get the last 'numOrders' orders
    
      // Check if ordersToExport is not undefined and has length greater than 0
      if (ordersToExport && ordersToExport?.length > 0) {
        // Map each order to include only the desired fields
        const formattedOrders = ordersToExport?.map(order => ({
          'FirstName': order?.firstname,
          'LastName':order?.lastname,
          'Email': order?.email,
          'Mobile':order?.mobile,
          'Address': `${order?.address}, ${order?.city},${order?.state},${order?.pincode}`,
        }));
    
        // Convert formatted orders data to Excel format
        const worksheet = XLSX.utils.json_to_sheet(formattedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers');
    
        // Save the workbook as an Excel file
        XLSX.writeFile(workbook, 'customer.xlsx');
      } else {
        console.error('No orders to export.');
      }
    };

  return (
    <div className='customer'>
      <div className="customer-head">
        <p>Customers</p>
        <div>
        <input type="number" value={numOrders} onChange={e => setNumOrders(e.target.value)}/>
                      <button onClick={exportToExcel}>Export</button>

        </div>
      </div>
      <div className="customer-list">
            <div className="table">
                <table>
                    <thead className='thead'>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>City</th>
                            <th>Orders</th>
                            <th>Order Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            customerState?.user?.slice().reverse().map((item,index)=>{
                                return(
                                    <Link to={`/customer/${item?._id}`}>
                                    <tr>
                                <td><span>Id:</span>{((page-1)*50)+index}</td>
                                <td><span>Name:</span>{item?.firstname}</td>
                                <td><span>Email:</span>{item?.email}</td>
                                <td><span>Phone:</span>{item?.mobile}</td>
                                <td><span>City:</span>{item?.city}</td>
                                <td><span>Order:</span>{item?.total_orders}</td>
                                <td><span>Amount:</span>&#8377; {(typeof item['total _spent'] === 'string' ? item['total _spent'].replace(/ /g, "_") : item['total _spent'])}</td>
                            </tr>
                            </Link>
                                )
                            })
                        }
                            
                    </tbody>
                </table>
            </div>
      </div>
      <div className="paginate">
        <button onClick={prevPage}>Prev</button>
        <p>{page}</p>
        <button onClick={nextPage}>Next</button>
      </div>
    </div>
  )
}

export default CustomerList
