import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const login = async (user) => {
  const response = await axios.post(`${base_url}user/admin-login`, user);
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};
const getOrders = async (data) => {
  const response = await axios.get(`${base_url}user/getallorders?limit=50&page=${data.page}`, config);

  return response.data;
};

const createAdmin = async (admin) => {
  const response = await axios.post(`${base_url}user/create-admin-dash`, admin,config);

  return response.data;
};
const resetPass = async (pass) => {
  const response = await axios.post(`${base_url}user/reset-password-admin`, pass);

  return response.data;
};


const getOrders1 = async (data) => {
  const response = await axios.get(`${base_url}user/getallorders?search=${data.search}`, config);

  return response.data;
};


const getAllOrder = async (data) => {
  const response = await axios.get(`${base_url}user/getallorders?limit=${data.limit}&page=1`, config);

  return response.data;
};
const getAllHistory = async (data) => {
  const response = await axios.get(`${base_url}user/gethistory?limit=50&page=${data.page}`, config);

  return response.data;
};
const getOrder = async (id) => {
  console.log(config)

  const response = await axios.get(
    `${base_url}user/getaOrder/${id}`,
    config
  );

  return response.data;
};
const getOldOrder = async () => {
  const response = await axios.get(
    `${base_url}user/getoldorders`,
    config
  );

  return response.data;
};
const getAbandoneds = async (data) => {
  const response = await axios.get(`${base_url}user/getallabandoned?limit=50&page=${data.page}`, config);

  return response.data;
};
const getAbandoned = async (id) => {
  const response = await axios.get(
    `${base_url}user/getaAbandoned/${id}`,
    config
  );

  return response.data;
};
const createOrder=async(orderDetails)=>{
  const response=await axios.post(`${base_url}user/cart/create-order`,orderDetails,config)
  if(response.data){
     
      return response.data
  }
}
const sendTracking=async(data)=>{
  const response=await axios.post(`${base_url}user/sendTracking`,{name:data.name,ordernumber:data.ordernumber,partner:data.partner,link:data.link,number:data.number,orderId:data.orderId})
  if(response.data){
     
      return response.data
  }
}
const sendDelivery=async(data)=>{
  const response=await axios.post(`${base_url}user/sendDelivery`,{name:data.name,ordernumber:data.ordernumber,number:data.number,orderId:data.orderId})
  if(response.data){
     
      return response.data
  }
}
const createHistory=async(historyDetail)=>{
  const response=await axios.post(`${base_url}user/createhistory`,historyDetail,config)
  if(response.data){
     
      return response.data
  }
}

const message = async (orderId, message, name, time) => {
  try {
      const response = await axios.put(`${base_url}user/message`, {orderId, message, name, time},config);
      if (response.data) {
          return response.data;
      }
  } catch (error) {
      console.error("Error message order:", error);
      // Handle error appropriately
      throw error;
  }
}
const history = async (orderId, message, name, time) => {
  try {
      const response = await axios.put(`${base_url}user/history`, {orderId, message, name, time},config);
      if (response.data) {
          return response.data;
      }
  } catch (error) {
      console.error("Error history order:", error);
      // Handle error appropriately
      throw error;
  }
}
const updateOrder = async (data) => {
  const response = await axios.put(
    `${base_url}user/updateOrder/${data.id}`,
    {
      shippingInfo:data.orderData.shippingInfo,
      paymentInfo:data.orderData.paymentInfo,
      orderItems:data.orderData.orderItems,
      totalPrice:data.orderData.totalPrice,
      shippingCost:data.orderData.shippingCost,
      orderType:data.orderData.orderType,
      discount:data.orderData.discount,
      finalAmount:data.orderData.finalAmount,
      orderStatus:data.orderData.orderStatus,
      createdAt:data.orderData.createdAt,
      tag:data.orderData.tag,
      orderCalled:data.orderData.orderCalled,

    }
    ,config
  );

  return response.data;
};
const updateAbandoned = async (data) => {
  const response = await axios.put(
    `${base_url}user/updateAbandoned/${data.id}`,
    {
      shippingInfo:data.orderData.shippingInfo,
      orderItems:data.orderData.orderItems,
      totalPrice:data.orderData.totalPrice,
      shippingCost:data.orderData.shippingCost,
      orderType:data.orderData.orderType,
      discount:data.orderData.discount,
      finalAmount:data.orderData.finalAmount,
      createdAt:data.orderData.createdAt,
      tag:data.orderData.tag,
      orderCalled:data.orderData.orderCalled,
      isPartial:data.orderData.isPartial
    }
    ,config
  );

  return response.data;
};
const getMonthlyOrders = async () => {
  const response = await axios.get(
    `${base_url}user/getMonthWiseOrderIncome`,
    config
  );

  return response.data;
};
const prepaidOrder = async (data) => {
  console.log(config)
  const response = await axios.put(
    `${base_url}user/prepaidOrder/${data}`,
    "",config
  );

  return response.data;
};
const codOrder = async (orderId) => {
  const response = await axios.put(
    `${base_url}user/codOrder/${orderId}`,"",
    config
  );

  return response.data;
};
const cancelOrder = async (orderId) => {
  const response = await axios.put(
    `${base_url}user/cancelOrder/${orderId}`,"",
    config
  );

  return response.data;
};
const returnOrder = async (orderId) => {
  const response = await axios.put(
    `${base_url}user/returnOrder/${orderId}`,"",
    config
  );

  return response.data;
};
const retrieveOrder = async (orderId) => {
  const response = await axios.put(
    `${base_url}user/retrieveOrder/${orderId}`,"",
    config
  );

  return response.data;
};
const deleteAbandoned = async (id) => {
  const response = await axios.delete(
    `${base_url}user/deleteabandoned/${id}`,
    config
  );

  return response.data;
};
const getYearlyStats = async () => {
  const response = await axios.get(
    `${base_url}user/getYearlyTotalOrders`,
    config
  );

  return response.data;
};
const getTodayOrders = async () => {
  const response = await axios.get(
    `${base_url}user/getTodayTotalOrders`,
    config
  );

  return response.data;
};
const getYesterdayOrders = async () => {
  const response = await axios.get(
    `${base_url}user/getYesterdayTotalOrders`,
    config
  );

  return response.data;
};
const getWeekOrders = async () => {
  const response = await axios.get(
    `${base_url}user/getWeekTotalOrders`,
    config
  );

  return response.data;
};
const getCustomOrders = async (data) => {
  const response = await axios.get(
    `${base_url}user/getCustomTotalOrders?startDate=${data.startDate}&endDate=${data.endDate}`
  );

  return response.data;
};
const getlastData = async () => {
  const response = await axios.get(
    `${base_url}user/graphData`
  );

  return response.data;
};

const authService = {
  login,
  getOrders,
  getOrder,
  getOldOrder,
  getMonthlyOrders,
  getYearlyStats,
  updateOrder,
  getTodayOrders,
  createOrder,
  getWeekOrders,
  getYesterdayOrders,
  message,
  history,
  getAbandoneds,
  getAbandoned,
  getAllOrder,
  getAllHistory,
  createHistory,
  updateAbandoned,
  cancelOrder,
  retrieveOrder,
  prepaidOrder,
  codOrder,
  sendTracking,
  sendDelivery,
  getCustomOrders,
  getlastData,
  returnOrder,
  getOrders1,
  deleteAbandoned,
  createAdmin,
  resetPass
};

export default authService;
