import axios from "axios";
import { base_url } from "../../utils/baseUrl";

const getUsers = async (data) => {
  const response = await axios.get(`${base_url}user/all-users?limit=50&page=${data.page}`);

  return response.data;
};
const getUsers1 = async () => {
  const response = await axios.get(`${base_url}user/all-users?limit=50000&page=1`);

  return response.data;
};

const customerService = {
  getUsers,
  getUsers1
};

export default customerService;
