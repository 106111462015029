import React, { useEffect } from 'react'
import './coupon.css'
import {useDispatch,useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import { getAllCoupon } from '../../features/coupon/couponSlice'
const CouponList = () => {
    const dispatch=useDispatch()
    const navigate=useNavigate()

    
    const couponState = useSelector((state) => state.coupon.coupons);
    useEffect(()=>{
        dispatch(getAllCoupon())
    },[dispatch])


  return (
    <div className='coupon'>
      <div className="coupon-head">
        <p>Coupons</p>
        <button onClick={()=>navigate("/coupon/new")}>Create Coupon</button>
      </div>
      <div className="coupon-list">
        {
          couponState?.map((item,index)=>{
            return(
              <Link to={`/coupon/${item._id}`} key={index}>
              <div className="coupon-card">
                  <div className="left">
                    {
                    item?.discount?.endsWith("%")?<p className='amount' >{item?.discount}</p> :<p className='amount'>&#8377; {item?.discount}</p>
                    }
                          
                          <p>OFF</p>
  
                  </div>
                  <div className="right">
                      <p className='name'>{item?.name}</p>
                      <p className='date'>{new Date(item?.expiry).toLocaleDateString('en-GB')}</p>
                      {
                        item?.discounttype==="order"?<p className='type'>Order Discount</p>:
                        item?.discounttype==="buyX"?<p className='type'>Buy X get Y</p>:
                        <p className='type'>Free Shipping</p>
                      }
                      {
                        item?.customertype==="all"?<p className='type'>All Customers</p>:
                        <p className='type'>{item?.cEmail}</p>
                      }
                      <p className='status'>{item?.status}</p>
                  </div>
              </div>
              </Link>
            )
          })
        }
           
      </div>
    </div>
  )
}

export default CouponList
