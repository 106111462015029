import React, {useState} from 'react'
import './forgot.css'
import {passReset} from '../../features/auth/authSlice'
import {useDispatch} from 'react-redux'
import toast from 'react-hot-toast'
const Forgot = () => {
  const dispatch=useDispatch()
  const [email,setEmail]=useState("")
  const [mobile,setMobile]=useState(null)
  const [newPassword,setNewPassword]=useState("")
  const [secretWord,setSecretWord]=useState("")
  
  
  const resetPassword=()=>{
    if(email==="" || mobile===null || newPassword==="" || secretWord===""){
      toast.error("Please Fill all the fields")
    }
    else{
      dispatch(passReset({email,mobile,newPassword,secretWord}))
    }
  }
  return (
    <div className='forgot'>
      <div className='form'>
      <div className="email">
            <input type="email" placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </div>
        <div className="email">
            <input type="number" placeholder='Mobile' value={mobile} onChange={(e)=>setMobile(e.target.value)}/>
        </div>
        <div className="email">
            <input type="text" placeholder='Password' value={newPassword} onChange={(e)=>setNewPassword(e.target.value)}/>
        </div>
        <div className="email">
            <input type="text" placeholder='Secret Code' value={secretWord} onChange={(e)=>setSecretWord(e.target.value)}/>
        </div>
        <button onClick={resetPassword}>Reset Password</button>
      </div>
    </div>
  )
}

export default Forgot
