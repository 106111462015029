import axios from "axios";
import { base_url } from "../../utils/baseUrl";
import { config } from "../../utils/axiosconfig";

const getBanners = async () => {
  const response = await axios.get(`${base_url}banner/`);

  return response.data;
};
const createBanner = async (banner) => {
  const response = await axios.post(`${base_url}banner/`, banner, config);

  return response.data;
};

const updateBanner = async (banner) => {
  const response = await axios.put(
    `${base_url}banner/${banner.id}`,
    banner.data ,
    config
  );

  return response.data;
};
const deleteBanner = async (id) => {
  const response = await axios.delete(`${base_url}banner/${id}`, config);

  return response.data;
};
const getBanner = async (id) => {
  const response = await axios.get(`${base_url}banner/${id}`, config);

  return response.data;
};
const bannerService = {
  getBanners,
  createBanner,
  updateBanner,
  deleteBanner,
  getBanner
};

export default bannerService;
