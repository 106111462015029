import React, { useState,useEffect } from 'react'
import './orders.css'
import SearchIcon from '@mui/icons-material/Search';
import {createhistory} from '../../features/auth/authSlice'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {getAProduct, getsearchProducts} from '../../features/product/productSlice'
import {createAnOrder} from '../../features/auth/authSlice'
import { getUsers } from '../../features/cutomers/customerSlice';
import { useDispatch, useSelector } from 'react-redux'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CreateOrder = () => {
  const [openDrops, setOpenDrops] = useState([]);
  const [open, setOpen] =useState(false);
  const [quantity,setQuantity]=useState(1)
  const [discount,setDiscount]=useState(0)
  const [shipping,setShipping]=useState(0)
  const [amountInput, setAmountInput] = useState('0');
  const [open1, setOpen1] =useState(false);
  const [firstname,setFirstname]=useState("")
  const [lastname,setLastname]=useState("")
  const [email,setEmail]=useState("")
  const [phone,setPhone]=useState("")
  const [address,setAddress]=useState("")
  const [city,setCity]=useState("")
  const [state,setState]=useState("")
  const [pincode,setPincode]=useState("")
  const [orderType,setOrderType]=useState("COD")
  const [tag,setTag]=useState("Voguemine")
  const user=JSON.parse(localStorage.getItem("user"))

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && query!=="") {
      dispatch(getsearchProducts({search:query}))
      setQuery("")
    }
  };
  const toggleDrop = (index) => {
    const updatedDrops = [...openDrops];
    updatedDrops[index] = !updatedDrops[index];
    setOpenDrops(updatedDrops);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

const dispatch = useDispatch();


const productState = useSelector((state) => state?.product?.searchProduct);
const userState = useSelector((state) => state?.customer?.customers);
useEffect(()=>{
  dispatch(getUsers())

},[dispatch])
function search(productState) {
  return productState?.products?.filter((productState) =>
    search_parameters.some((parameter) =>
      productState[parameter]?.toString()?.toLowerCase()?.includes(query)
    )
  );
}

function search1(userState) {

  return userState?.filter((userState) =>

    search_parameters1.some((parameter) =>

      userState[parameter]?.toString()?.toLowerCase()?.includes(query)

    )

  );

}
const [query, setQuery] = useState("");
const [query1, setQuery1] = useState("");


const search_parameters = Object.keys(Object.assign({}, ...productState?.products || []));
const search_parameters1 = Object.keys(Object.assign({}, ...userState));

const [selectedItems, setSelectedItems] = useState(Array(productState?.products?.length || 0).fill([]).map(() => []));

const [orderItems, setOrderItems] = useState([]);
let applyProduct;
const handleItemClick = (filteredIndex, variantIndex, newQuantity) => {
  const searchedProducts = search(productState) || [];
  const selectedProduct = searchedProducts[filteredIndex];

  if (!selectedProduct) {
    console.error('Product not found or filteredIndex is invalid');
    return;
  }

  const selectedVariant = selectedProduct.variants[variantIndex];
  const newItem = {
    product: {
      brand: selectedProduct.brand,
      category: selectedProduct.category,
      collectionName: selectedProduct.collectionName,
      description: selectedProduct.description,
      handle: selectedProduct.handle,
      id: selectedProduct._id,
      images: selectedProduct.images,
      price: selectedProduct.price,
      ratings: selectedProduct.ratings,
      sku: selectedProduct.sku,
      sold: selectedProduct.sold,
      state: selectedProduct.state,
      tags: selectedProduct.tags,
      title: selectedProduct.title,
      totalrating: selectedProduct.totalrating,
      updatedAt: selectedProduct.updatedAt,
      variants: selectedProduct.variants,
      _id: selectedProduct._id,
    },
    color: selectedVariant.color,
    size: selectedVariant.size,
    quantity: newQuantity,
    price: selectedProduct.price,
    sku: selectedProduct.sku,

  };

  const isChecked = selectedItems[filteredIndex]?.includes(variantIndex);

  if (!isChecked) {
    setOrderItems(prevItems => [...prevItems, newItem]);
  } else {
    setOrderItems(prevItems => prevItems.filter(item => item.product._id !== newItem.product._id || item.color !== newItem.color || item.size !== newItem.size));
  }

  setSelectedItems(prevState => {
    const newSelectedItems = [...prevState];
    if (!newSelectedItems[filteredIndex]) {
      newSelectedItems[filteredIndex] = [];
    }
    const currentIndex = newSelectedItems[filteredIndex].indexOf(variantIndex);
    if (currentIndex === -1) {
      newSelectedItems[filteredIndex].push(variantIndex);
    } else {
      newSelectedItems[filteredIndex].splice(currentIndex, 1);
    }
    return newSelectedItems;
  });
};
const handleQuantityChange = (index, newQuantity) => {
  setOrderItems(prevItems => {
    const updatedItems = [...prevItems];
    updatedItems[index].quantity = newQuantity;
    return updatedItems;
  });
};
const handleDeleteItem = (index) => {
  setOrderItems(prevItems => {
    const updatedItems = [...prevItems];
    updatedItems.splice(index, 1); // Remove the item at the specified index
    return updatedItems;
  });
};
const shippingAdd=()=>{
  if(shipping===0){
    setShipping(200)
  }
  else{
    setShipping(0)
  }
}


const subtotal = orderItems.reduce((total, item) => total + (item.price * item.quantity), 0);
const handleAmountInputChange = (e) => {
  setAmountInput(e.target.value);
};

const calculateUpdatedAmount = () => {
  if (!amountInput.trim()) return subtotal;

  if (amountInput.endsWith('%')) {
    const percentage = parseFloat(amountInput) / 100;
    const amountToChange = subtotal * percentage;
    return amountToChange;
  } else {
    const numericValue = parseFloat(amountInput);
    return numericValue;
  }
};

const updatedAmount = calculateUpdatedAmount();

const total=subtotal-updatedAmount+shipping
const handleCustomerClick=(data)=>{
  setFirstname(data?.firstname)
  setLastname(data?.lastname)
  setEmail(data?.email)
  setPhone(data?.phone)
  setAddress(data?.address)
  setCity(data?.city)
  setState(data?.state)
  setPincode(data?.province_code || data?.pincode)
}


const submitClick=()=>{
  dispatch(createAnOrder({
    shippingInfo:{
      firstname:firstname,
      lastname:lastname,
      email:email,
      phone:phone,
      address:address,
      city:city,
      state:state,
      pincode:pincode,
    },
    paymentInfo:
      {
        razorpayOrderId:"COD",
        razorpayPaymentId:"COD"
      },
      orderItems:orderItems,
      totalPrice:subtotal,
      shippingCost:shipping,
      orderType:orderType,
      discount:updatedAmount,
      finalAmount:total,
      tag:tag
    
  }))
  dispatch(createhistory({name:user?.firstname,title:"Order Created",sku:"",productchange:`For ${firstname}, Amount:${total}, orderType:${orderType}, Items:${orderItems?.length}`,time:new Date()}))


}


const modifyCloudinaryUrl = (url) => {
  const urlParts = url?.split('/upload/');
  return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
};

  return (
    <div className='new-order'>
      {/* <form action=""> */}
        <div className="head">
          <p>Create Order</p>
          <button onClick={submitClick} className='submit-btn'>Create</button>
        </div>
        <div className="order-data">
          <div className="data-left">
          <div className="products">
                <p className="prdt-head" style={{fontWeight:600,fontSize:'13px',margin:'15px 0'}}>Products</p>
                  <div className="search">
    <SearchIcon className='search-icon'/>
                    <input type="search" placeholder='Select Products' onClick={handleClickOpen}/>
                    <BootstrapDialog
                     className='dialog'
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Products
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className='dialog-content'>
          <div className="search-dialog">
          <SearchIcon className='search-icon'/>
                    <input type="search" placeholder='Select Products' onChange={(e) => setQuery(e.target.value)} onKeyDown={handleKeyDown}/>
          </div>
          <div className="product-list">
          {
          search(productState)?.map((dataObj,index) => {

return (
  <div className='list-product' key={index}>
    <div className="product-main">
      <div>
        <img src={modifyCloudinaryUrl(dataObj?.images && dataObj?.images[0]?.url)} alt="" />
        <p>{dataObj?.title}<p style={{marginLeft:'0px',marginTop:'5px',fontWeight:500}}>{dataObj?.sku}</p></p>
        
      </div>
      <p className='drop-icon' onClick={() => toggleDrop(index)}>{openDrops[index] ? <KeyboardArrowUpIcon className='icon'/> : <KeyboardArrowDownIcon className='icon'/>}</p>
    </div>
    <div className="product-drop" style={{ display: openDrops[index] ? 'block' : 'none' }}>
      <ul>
      {
  dataObj?.variants?.map((item, idx) => {
    const isSelected = selectedItems[index]?.includes(idx);
    return (
      <li
        key={idx}
        className={`list-item ${isSelected ? 'selected-item' : ''}`}
        // onClick={() => handleItemClick(index, idx)}
      >
<input type="checkbox" name="" id="" onClick={() => handleItemClick(index, idx, quantity)} />
        <p>{item?.color} / {item?.size}</p>
        <p>{item?.quantity} available</p>
        <p>&#8377; {dataObj?.price}</p>
      </li>
    )
  })
}
      </ul>
    </div>
  </div>
)
}
)
}


          </div>
          <div className="apply-changes">
            <button onClick={handleClose} className='filter-btn'>Apply</button>
          </div>
        </DialogContent>
      </BootstrapDialog>
                  </div>

                  <div className="product">
                    <div className='headings'>
                      <p>Product</p>
                      <p>Quantity</p>
                      <p>Price</p>
                    </div>
                    {
  orderItems?.map((item, index) => {
    return (
      <div className="product-data" key={index}>
        <div>
          <img src={modifyCloudinaryUrl(item?.product?.images && item?.product?.images[0]?.url)} alt="" />
          <div className="detail">
            <p className='title'>{item?.product?.title}</p>
            <p className='size'><span>{item?.size}</span> / <span>{item?.color}</span></p>
            <p className='sku'>SKU: {item?.product?.sku}</p>
            <p className='price'>&#8377; {item?.price}</p>
          </div>
        </div>
        <div>
          <input type="number" name="" id="" value={item.quantity} onChange={(e) => handleQuantityChange(index, e.target.value)} />
        </div>
        <div>
          <p>&#8377; {item.quantity * item.price}</p>
          <p><DeleteOutlineIcon className='delete-icon' onClick={() => handleDeleteItem(index)} /></p>
        </div>
      </div>
    );
  })
}
                  </div>
              </div>
              <div className="payment">
              <p className="prdt-head" style={{fontWeight:600,fontSize:'13px',margin:'15px 0'}}>Payment</p>
                <div className="payment-info">
                  <div className="left">
                      <p>Subtotal</p>
                      <p >Add Discount</p>
                      <p style={{cursor:'pointer',color:'blue'}} onClick={shippingAdd}>{
                        shipping===0?"Add Shipping":"Remove Shipping"
}</p>
                      <p>Total</p>

                  </div>
                  <div className="center">
                  <p>{orderItems?.length} Item</p>
                      <p><input type="text" value={amountInput} onChange={(e)=>handleAmountInputChange(e)} placeholder='Discount'/></p>
                      <p>-</p>
                      <p>-</p>
                  </div>
                  <div className="right">
                  <p>&#8377; {subtotal}</p>
                      <p>&#8377; {updatedAmount}</p>
                      <p>&#8377; {shipping}</p>
                      <p>&#8377; {total}</p>
                  </div>
                </div>
              </div>
          </div>
          <div className="data-right">
          <div className="notes">
              <p className="prdt-head" style={{fontWeight:600,fontSize:'13px',margin:'15px 0'}}>Select Brand</p>
<select name="" id="" value={tag} onChange={(e)=>setTag(e.target.value)}>
  <option value="Voguemine">Voguemine</option>
  <option value="Rampvalk">Rampvalk</option>
</select>
              </div>
              <div className="notes">
              <p className="prdt-head" style={{fontWeight:600,fontSize:'13px',margin:'15px 0'}}>Order Type</p>
<select name="" id="" value={orderType} onChange={(e)=>setOrderType(e.target.value)}>
  <option value="COD">COD</option>
  <option value="Prepaid">Prepaid</option>
</select>
              </div>
              <div className="notes">
              <p className="prdt-head" style={{fontWeight:600,fontSize:'13px',margin:'15px 0'}}>Search Customer</p>
<input type="search" name="" id="" placeholder='Search Customer' onClick={handleClickOpen1}/>
<BootstrapDialog
                     className='dialog'
        onClose={handleClose1}
        aria-labelledby="customized-dialog-title"
        open={open1}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Customer
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose1}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className='dialog-content'>
          <div className="search-dialog">
          <SearchIcon className='search-icon'/>
                    <input type="search" placeholder='Select Customer' onChange={(e) => setQuery(e.target.value)}/>
          </div>
          <div className="product-list">
            <div className="head" style={{display:'grid',gridTemplateColumns:'1fr 2fr 1fr',fontSize:'13px',fontWeight:600,gridGap:'10px'}}>
              <p>Name</p>
              <p>Email</p>
              <p>Phone</p>
            </div>
          {
          search1(userState)?.map((dataObj,index) => {

return (
  <div className='list-customer' key={index} onClick={()=>handleCustomerClick(dataObj)}>
    <p>{dataObj?.firstname}</p>
    <p>{dataObj?.email}</p>
    <p>{dataObj?.mobile}</p>
</div>
    )
  })
}



          </div>
          <div className="apply-changes">
            <button onClick={handleClose1} className='filter-btn'>Add</button>
          </div>
        </DialogContent>
      </BootstrapDialog>
<div className="customer-detail">
<p className="prdt-head" style={{fontWeight:600,fontSize:'13px',margin:'15px 0',display:'flex',alignItems:'center',justifyContent:'space-between'}}><p>Customer Details</p><button>New Customer</button></p>


  <input type="text" placeholder='First Name' value={firstname} onChange={(e)=>setFirstname(e.target.value)}/>
  <input type="text" placeholder='Last Name' value={lastname} onChange={(e)=>setLastname(e.target.value)}/>
  <input type="email" placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
  <input type="number" placeholder='Phone' value={phone} onChange={(e)=>setPhone(e.target.value)}/>
  <input type="text" placeholder='Address' value={address} onChange={(e)=>setAddress(e.target.value)}/>
  <input type="text" placeholder='City' value={city} onChange={(e)=>setCity(e.target.value)}/>
  <input type="text" placeholder='State' value={state} onChange={(e)=>setState(e.target.value)}/>
  <input type="number" placeholder='Pin Code' value={pincode} onChange={(e)=>setPincode(e.target.value)}/>

</div>

              </div>
          </div>
        </div>
        {/* </form> */}
    </div>
  )
}

export default CreateOrder
