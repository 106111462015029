import React,{useEffect, useState} from 'react'
import './history.css'
import {gethistory} from '../../features/auth/authSlice'
import {useLocation,useNavigate } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux'
const History = () => {
  const navigate=useNavigate()

    const location = useLocation();
    const dispath=useDispatch()
    const historyState=useSelector((state)=>state.auth.getHistory)
    const queryParams = new URLSearchParams(location.search);
    let page = parseInt(queryParams.get('page')) || 1;
    const updateURL = (updateParams) => {
      const searchParams = new URLSearchParams();
    
      if (updateParams.page !== undefined) {
        searchParams.set('page', updateParams.page);
      }
      navigate(`/history?${searchParams.toString()}`);
    };
    useEffect(()=>{
        dispath(gethistory({page}))
    },[page])
    const nextPage=()=>{
      page++;
      updateURL({ page });
      }
      const prevPage=()=>{
        if (page > 1) {
          page--;
          updateURL({ page });
        }
      }
  return (
    <div className='history'>
      <div className="section-heading">
        History
      </div>

      <div className="section-body">
        <div className="hist">
          <p style={{fontWeight:500,marginBottom:'10px'}}>Name</p>
          <p style={{fontWeight:500,marginBottom:'10px'}}>Detail</p>
          <p style={{fontWeight:500,marginBottom:'10px'}}>Changes</p>
          <p style={{fontWeight:500,marginBottom:'10px'}}>Time</p>
        </div>
        {
          historyState?.history?.slice()?.reverse()?.map((item,index)=>{
return  <div className="hist" key={index}>
<div className="name">
<p>{item?.name}</p>
</div>
<div className="prdt">
<p>{item?.title}</p>
<p>{item?.sku}</p>
</div>
<div className="change">
<p>{item?.productchange}</p>
</div>
<div className="time">
<p>{new Date(item?.time).toLocaleString('en-GB', { hour12: true })}</p>
</div>

</div>
          })
        }
       
      </div>
      <div className="paginate" >
        <button onClick={prevPage} disabled={page===1 ? true:false} style={{backgroundColor:page===1 ? "grey":"",cursor:page===1? "context-menu":""}}>Prev</button>
        <p>{page}</p>
        <button onClick={nextPage} disabled={historyState?.history?.length<50 ? true:false} style={{backgroundColor:historyState?.history?.length<50 ? "grey":"",cursor:historyState?.history?.length<500 ? "context-menu":""}}>Next</button>
      </div>
    </div>
  )
}

export default History
